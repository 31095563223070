import { v4 as uuid } from "uuid";
import { create } from "zustand";
import { mutative } from "zustand-mutative";

export interface ControlSection {
  expanded: boolean;
  category: string;
  label: string;
}

export type SecurityControlCategory =
  | "Logical and Physical Access Controls"
  | "Risk Mitigation"
  | "Communication and Information"
  | "Change Management "
  | "Monitoring Activities"
  | "System Operations"
  | "Control Activities "
  | "Risk Assessment"
  | "Control Environment";

export const SecurityControlCategoryOption = [
  {
    value: "Logical and Physical Access Controls",
    label: "Logical and Physical Access Controls",
  },
  {
    value: "Risk Mitigation",
    label: "Risk Mitigation",
  },
  {
    value: "Communication and Information",
    label: "Communication and Information",
  },
  {
    value: "Change Management",
    label: "Change Management",
  },
  {
    value: "Monitoring Activities",
    label: "Monitoring Activities",
  },
  {
    value: "System Operations",
    label: "System Operations",
  },
  {
    value: "Control Activities",
    label: "Control Activities",
  },
  {
    value: "Risk Assessment",
    label: "Risk Assessment",
  },
  {
    value: "Control Environment",
    label: "Control Environment",
  },
];

export interface Control {
  tod: string;
  uid: string;
  category: SecurityControlCategory | "";
  tsc: TSCENUM;
  tsc_id: string;
  criteria: string;
  control_id: string;
  zania_control_id: string;
  control: string;
  toe: string;
  edited_state: "default" | "custom" | "edited" | "NEW" | "ACCORDIAN";
  selected: boolean;
}

export enum TSCENUM {
  AVAILABILITY = "availability",
  CONFIDENTIALITY = "confidentiality",
  PROCESSING_INTEGRITY = "processing integrity",
  PRIVACY = "privacy",
  SECURITY = "security",
}

export const TSCABBRIVATIONS = {
  [TSCENUM.AVAILABILITY]: "A",
  [TSCENUM.CONFIDENTIALITY]: "C",
  [TSCENUM.PROCESSING_INTEGRITY]: "PI",
  [TSCENUM.PRIVACY]: "P",
  [TSCENUM.SECURITY]: "CC",
} as const;

export interface CustomizeControlsStore {
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  };
  selectedControls: Set<string>;
  dataState: Control[];
  actions: {
    setAccordianOpenState: (key: TSCENUM, newValue: boolean) => void;
    setDataState: (newValue: Control[] | ((prev: Control[]) => Control[])) => void;
    setSelectedControls: (newVal: Set<string> | ((prev: Set<string>) => Set<string>)) => void;
    reset: () => void;
  };
}
export const data: Control[] = [
  {
    uid: "el1etbxa",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.1",
    criteria: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
    control_id: "CC1.1.1",
    zania_control_id: "zania.CC1.1.1",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Sets the Tone at the Top-The board of directors and management, at all levels, demonstrate through their directives, actions, and behavior the importance of integrity and ethical values to support the functioning of the system of internal control.",
  },
  {
    uid: "cmuudjvx",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.1",
    criteria: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
    control_id: "CC1.1.2",
    zania_control_id: "zania.CC1.1.2",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Establishes Standards of Conduct-The expectations of the board of directors and senior management concerning integrity and ethical values are defined in the entity's standards of conduct and understood at all levels of the entity and by outsourced service providers and business partners.",
  },
  {
    uid: "0ac4sfhz",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.1",
    criteria: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
    control_id: "CC1.1.3",
    zania_control_id: "zania.CC1.1.3",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Evaluates Adherence to Standards of Conduct-Processes are in place to evaluate the performance of individuals and teams against the entity's expected standards of conduct.",
  },
  {
    uid: "0pcv9udz",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.1",
    criteria: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
    control_id: "CC1.1.4",
    zania_control_id: "zania.CC1.1.4",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Addresses Deviations in a Timely Manner-Deviations from the entity's expected standards of conduct are identified and remedied in a timely and consistent manner.",
  },
  {
    uid: "mpae3u77",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.1",
    criteria: "COSO Principle 1: The entity demonstrates a commitment to integrity and ethical values.",
    control_id: "CC1.1.5",
    zania_control_id: "zania.CC1.1.5",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Considers Contractors and Vendor Employees in Demonstrating Its Commitment-Management and the board of directors consider the use of contractors and vendor employees in its processes for establishing standards of conduct, evaluating adherence to those standards, and addressing deviations in a timely manner.",
  },
  {
    uid: "vj5nq58y",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.2",
    criteria:
      "COSO Principle 2: The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
    control_id: "CC1.2.1",
    zania_control_id: "zania.CC1.2.1",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Establishes Oversight Responsibilities-The board of directors identifies and accepts its oversight responsibilities in relation to established requirements and expectations.",
  },
  {
    uid: "5k3aq9yn",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.2",
    criteria:
      "COSO Principle 2: The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
    control_id: "CC1.2.2",
    zania_control_id: "zania.CC1.2.2",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Applies Relevant Expertise-The board of directors defines, maintains, and periodically evaluates the skills and expertise needed among its members to enable them to ask probing questions of senior management and take commensurate action.",
  },
  {
    uid: "ju9tj73n",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.2",
    criteria:
      "COSO Principle 2: The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
    control_id: "CC1.2.3",
    zania_control_id: "zania.CC1.2.3",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Operates Independently-The board of directors has sufficient members who are independent from management and objective in evaluations and decision making.",
  },
  {
    uid: "6yd6y32h",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.2",
    criteria:
      "COSO Principle 2: The board of directors demonstrates independence from management and exercises oversight of the development and performance of internal control.",
    control_id: "CC1.2.4",
    zania_control_id: "zania.CC1.2.4",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Supplements Board Expertise-The board of directors supplements its expertise relevant to security, availability, processing integrity, confidentiality, and privacy, as needed, through the use of a subcommittee or consultants.",
  },
  {
    uid: "55hrf9tw",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.3",
    criteria:
      "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
    control_id: "CC1.3.1",
    zania_control_id: "zania.CC1.3.1",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Considers All Structures of the Entity-Management and the board of directors consider the multiple structures used (including operating units, legal entities, geographic distribution, and outsourced service providers) to support the achievement of objectives.",
  },
  {
    uid: "fg4ov56d",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.3",
    criteria:
      "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
    control_id: "CC1.3.2",
    zania_control_id: "zania.CC1.3.2",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Establishes Reporting Lines-Management designs and evaluates lines of reporting for each entity structure to enable execution of authorities and responsibilities and flow of information to manage the activities of the entity.",
  },
  {
    uid: "txfif0ks",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.3",
    criteria:
      "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
    control_id: "CC1.3.3",
    zania_control_id: "zania.CC1.3.3",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Defines, Assigns, and Limits Authorities and Responsibilities-Management and the board of directors delegate authority, define responsibilities, and use appropriate processes and technology to assign responsibility and segregate duties as necessary at the various levels of the organization.",
  },
  {
    uid: "gmu5m6bv",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.3",
    criteria:
      "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
    control_id: "CC1.3.4",
    zania_control_id: "zania.CC1.3.4",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Addresses Specific Requirements When Defining Authorities and Responsibilities-Management and the board of directors consider requirements relevant to security, availability, processing integrity, confidentiality, and privacy when defining authorities and responsibilities.",
  },
  {
    uid: "3zow3dv2",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.3",
    criteria:
      "COSO Principle 3: Management establishes, with board oversight, structures, reporting lines, and appropriate authorities and responsibilities in the pursuit of objectives.",
    control_id: "CC1.3.5",
    zania_control_id: "zania.CC1.3.5",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Considers Interactions With External Parties When Establishing Structures, Reporting Lines, Authorities, and Responsibilities-Management and the board of directors consider the need for the entity to interact with and monitor the activities of external parties when establishing structures, reporting lines, authorities, and responsibilities.",
  },
  {
    uid: "m144ulyv",
    category: "Control Environment",
    tsc: TSCENUM.SECURITY,
    tsc_id: "CC1.4",
    criteria:
      "COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.",
    control_id: "CC1.4.1",
    zania_control_id: "zania.CC1.4.1",
    edited_state: "default",
    selected: true,
    toe: "",
    tod: "",
    control:
      "Establishes Policies and Practices-Policies and practices reflect expectations of competence necessary to support the achievement of objectives.",
  },
  // {
  //   uid: 'lytbuopr',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.2',
  //   zania_control_id: 'zania.CC1.4.2',
  // toe:'',
  //   control:
  //     'Evaluates Competence and Addresses Shortcomings-The board of directors and management evaluate competence across the entity and in outsourced service providers in relation to established policies and practices and act as necessary to address shortcomings.',
  // },
  // {
  //   uid: 'jvk496bj',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.3',
  //   zania_control_id: 'zania.CC1.4.3',
  // toe:'',
  //   control:
  //     'Attracts, Develops, and Retains Individuals-The entity provides the mentoring and training needed to attract, develop, and retain sufficient and competent personnel and outsourced service providers to support the achievement of objectives.',
  // },
  // {
  //   uid: 'tnhn95d6',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.4',
  //   zania_control_id: 'zania.CC1.4.4',
  // toe:'',
  //   control:
  //     'Plans and Prepares for Succession-Senior management and the board of directors develop contingency plans for assignments of responsibility important for internal control.',
  // },
  // {
  //   uid: 'wk6zu0rc',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.5',
  //   zania_control_id: 'zania.CC1.4.5',
  // toe:'',
  //   control:
  //     'Considers the Background of Individuals-The entity considers the background of potential and existing personnel, contractors, and vendor employees when determining whether to employ and retain the individuals.',
  // },
  // {
  //   uid: 'd2b3gnjp',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.6',
  //   zania_control_id: 'zania.CC1.4.6',
  // toe:'',
  //   control:
  //     'Considers the Technical Competency of Individuals-The entity considers the technical competency of potential and existing personnel, contractors, and vendor employees when determining whether to employ and retain the individuals.',
  // },
  // {
  //   uid: 'r92u0j4k',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.4',
  //   criteria:
  //     'COSO Principle 4: The entity demonstrates a commitment to attract, develop, and retain competent individuals in alignment with objectives.',
  //   control_id: 'CC1.4.7',
  //   zania_control_id: 'zania.CC1.4.7',
  // toe:'',
  //   control:
  //     'Provides Training to Maintain Technical Competencies- The entity provides training programs, including continuing education and training, to ensure skill sets and technical competency of existing personnel, contractors, and vendor employees are developed and maintained.',
  // },
  // {
  //   uid: 'tqn2nib3',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.5',
  //   criteria:
  //     'COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.',
  //   control_id: 'CC1.5.1',
  //   zania_control_id: 'zania.CC1.5.1',
  // toe:'',
  //   control:
  //     'Enforces Accountability Through Structures, Authorities, and Responsibilities-Management and the board of directors establish the mechanisms to communicate and hold individuals accountable for performance of internal control responsibilities across the entity and implement corrective action as necessary.',
  // },
  // {
  //   uid: 'yt8w8yoj',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.5',
  //   criteria:
  //     'COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.',
  //   control_id: 'CC1.5.2',
  //   zania_control_id: 'zania.CC1.5.2',
  // toe:'',
  //   control:
  //     'Establishes Performance Measures, Incentives, and Rewards-Management and the board of directors establish performance measures, incentives, and other rewards appropriate for responsibilities at all levels of the entity, reflecting appropriate dimensions of performance and expected standards of conduct, and considering the achievement of both short-term and longer-term objectives.',
  // },
  // {
  //   uid: 'tj79tnkz',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.5',
  //   criteria:
  //     'COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.',
  //   control_id: 'CC1.5.3',
  //   zania_control_id: 'zania.CC1.5.3',
  // toe:'',
  //   control:
  //     'Evaluates Performance Measures, Incentives, and Rewards for Ongoing Relevance-Management and the board of directors align incentives and rewards with the fulfillment of internal control responsibilities in the achievement of objectives.',
  // },
  // {
  //   uid: 'rhauw0id',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.5',
  //   criteria:
  //     'COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.',
  //   control_id: 'CC1.5.4',
  //   zania_control_id: 'zania.CC1.5.4',
  // toe:'',
  //   control:
  //     'Considers Excessive Pressures-Management and the board of directors evaluate and adjust pressures associated with the achievement of objectives as they assign responsibilities, develop performance measures, and evaluate performance.',
  // },
  // {
  //   uid: '4u00v3s8',
  //   category: 'Control Environment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC1.5',
  //   criteria:
  //     'COSO Principle 5: The entity holds individuals accountable for their internal control responsibilities in the pursuit of objectives.',
  //   control_id: 'CC1.5.5',
  //   zania_control_id: 'zania.CC1.5.5',
  // toe:'',
  //   control:
  //     'Evaluates Performance and Rewards or Disciplines Individuals-Management and the board of directors evaluate performance of internal control responsibilities, including adherence to standards of conduct and expected levels of competence, and provide rewards or exercise disciplinary action, as appropriate.',
  // },
  // {
  //   uid: 'c5xd745s',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.1',
  //   criteria:
  //     'COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.',
  //   control_id: 'CC2.1.1',
  //   zania_control_id: 'zania.CC2.1.1',
  // toe:'',
  //   control:
  //     "Identifies Information Requirements-A process is in place to identify the information required and expected to support the functioning of the other components of internal control and the achievement of the entity's objectives.",
  // },
  // {
  //   uid: 'kreifttt',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.1',
  //   criteria:
  //     'COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.',
  //   control_id: 'CC2.1.2',
  //   zania_control_id: 'zania.CC2.1.2',
  // toe:'',
  //   control:
  //     'Captures Internal and External Sources of Data-Information systems capture internal and external sources of data.',
  // },
  // {
  //   uid: 'vyo4x51w',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.1',
  //   criteria:
  //     'COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.',
  //   control_id: 'CC2.1.3',
  //   zania_control_id: 'zania.CC2.1.3',
  // toe:'',
  //   control:
  //     'Processes Relevant Data Into Information-Information systems process and transform relevant data into information.',
  // },
  // {
  //   uid: 'vp7007bw',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.1',
  //   criteria:
  //     'COSO Principle 13: The entity obtains or generates and uses relevant, quality information to support the functioning of internal control.',
  //   control_id: 'CC2.1.4',
  //   zania_control_id: 'zania.CC2.1.4',
  // toe:'',
  //   control:
  //     'Maintains Quality Throughout Processing-Information systems produce information that is timely, current, accurate, complete, accessible, protected, verifiable, and retained. Information is reviewed to assess its relevance in supporting the internal control components.',
  // },
  // {
  //   uid: 'b4go6txc',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.1',
  //   zania_control_id: 'zania.CC2.2.1',
  // toe:'',
  //   control:
  //     'Communicates Internal Control Information-A process is in place to communicate required information to enable all personnel to understand and carry out their internal control responsibilities.',
  // },
  // {
  //   uid: 'c5p63dty',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.2',
  //   zania_control_id: 'zania.CC2.2.2',
  // toe:'',
  //   control:
  //     "Communicates With the Board of Directors-Communication exists between management and the board of directors so that both have information needed to fulfill their roles with respect to the entity's objectives.",
  // },
  // {
  //   uid: '82z65id8',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.3',
  //   zania_control_id: 'zania.CC2.2.3',
  // toe:'',
  //   control:
  //     'Provides Separate Communication Lines-Separate communication channels, such as whistle-blower hotlines, are in place and serve as fail-safe mechanisms to enable anonymous or confidential communication when normal channels are inoperative or ineffective.',
  // },
  // {
  //   uid: 'qr324dj7',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.4',
  //   zania_control_id: 'zania.CC2.2.4',
  // toe:'',
  //   control:
  //     'Selects Relevant Method of Communication-The method of communication considers the timing, audience, and nature of the information.',
  // },
  // {
  //   uid: 'tsgc2gru',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.5',
  //   zania_control_id: 'zania.CC2.2.5',
  // toe:'',
  //   control:
  //     'Communicates Responsibilities-Entity personnel with responsibility for designing, developing, implementing, operating, maintaining, or monitoring system controls receive communications about their responsibilities, including changes in their responsibilities, and have the information necessary to carry out those responsibilities.',
  // },
  // {
  //   uid: 'bapdfnia',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.6',
  //   zania_control_id: 'zania.CC2.2.6',
  // toe:'',
  //   control:
  //     'Communicates Information on Reporting Failures, Incidents, Concerns, and Other Matters-Entity personnel are provided with information on how to report systems failures, incidents, concerns, and other complaints to personnel.',
  // },
  // {
  //   uid: 'c15n590n',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.7',
  //   zania_control_id: 'zania.CC2.2.7',
  // toe:'',
  //   control:
  //     'Communicates Objectives and Changes to Objectives -The entity communicates its objectives and changes to those objectives to personnel in a timely manner.',
  // },
  // {
  //   uid: '7nq9g94o',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.2',
  //   criteria:
  //     'COSO Principle 14: The entity internally communicates information, including objectives and responsibilities for internal control, necessary to support the functioning of internal control.',
  //   control_id: 'CC2.2.8',
  //   zania_control_id: 'zania.CC2.2.8',
  // toe:'',
  //   control:
  //     'Communicates Information to Improve Security Knowledge and Awareness-The entity communicates information to improve security knowledge and awareness and to model appropriate security behaviors to personnel through a security awareness training program.',
  // },
  // {
  //   uid: 'ilu1w9sq',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.1',
  //   zania_control_id: 'zania.CC2.3.1',
  // toe:'',
  //   control:
  //     'Communicates to External Parties-Processes are in place to communicate relevant and timely information to external parties, including shareholders, partners, owners, regulators, customers, financial analysts, and other external parties.',
  // },
  // {
  //   uid: 'o5gbp4r1',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.2',
  //   zania_control_id: 'zania.CC2.3.2',
  // toe:'',
  //   control:
  //     'Enables Inbound Communications-Open communication channels allow input from customers, consumers, suppliers, external auditors, regulators, financial analysts, and others, providing management and the board of directors with relevant information.',
  // },
  // {
  //   uid: 'ht3krarm',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.3',
  //   zania_control_id: 'zania.CC2.3.3',
  // toe:'',
  //   control:
  //     'Communicates With the Board of Directors-Relevant information resulting from assessments conducted by external parties is communicated to the board of directors.',
  // },
  // {
  //   uid: '6o2uc37a',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.4',
  //   zania_control_id: 'zania.CC2.3.4',
  // toe:'',
  //   control:
  //     'Provides Separate Communication Lines-Separate communication channels, such as whistle-blower hotlines, are in place and serve as fail-safe mechanisms to enable anonymous or confidential communication when normal channels are inoperative or ineffective.',
  // },
  // {
  //   uid: '93slx1kk',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.5',
  //   zania_control_id: 'zania.CC2.3.5',
  // toe:'',
  //   control:
  //     'Communicates Objectives Related to Confidentiality and Changes to Objectives- The entity communicates, to external s, vendors, business partners and others whose products and services are part of the system, objectives and changes to objectives related to confidentiality.',
  // },
  // {
  //   uid: 'jb7x8ak5',
  //   category: 'Communication and Information',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC2.3',
  //   criteria:
  //     'COSO Principle 15: The entity communicates with external parties regarding matters affecting the functioning of internal control.',
  //   control_id: 'CC2.3.6',
  //   zania_control_id: 'zania.CC2.3.6',
  // toe:'',
  //   control:
  //     'Communicates Objectives Related to Privacy and Changes to Objectives-The entity communicates, to external users, vendors, business partners and others whose products and services are part of the system, objectives related to privacy and changes to those objectives.',
  // },
  // {
  //   uid: 'arlk6e3a',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.1',
  //   zania_control_id: 'zania.CC3.1.1',
  // toe:'',
  //   control:
  //     "Reflects Management's Choices-Operations objectives reflect management's choices about structure, industry considerations, and performance of the entity.",
  // },
  // {
  //   uid: '9yfthaa3',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.2',
  //   zania_control_id: 'zania.CC3.1.2',
  // toe:'',
  //   control:
  //     'Considers Tolerances for Risk-Management considers the acceptable levels of variation relative to the achievement of operations objectives.',
  // },
  // {
  //   uid: '25eiq2s1',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.3',
  //   zania_control_id: 'zania.CC3.1.3',
  // toe:'',
  //   control:
  //     'Includes Operations and Financial Performance Goals-The organization reflects the desired level of operations and financial performance for the entity within operations objectives.',
  // },
  // {
  //   uid: 'm0dx41zy',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.4',
  //   zania_control_id: 'zania.CC3.1.4',
  // toe:'',
  //   control:
  //     'Forms a Basis for Committing of Resources-Management uses operations objectives as a basis for allocating resources needed to attain desired operations and financial performance.',
  // },
  // {
  //   uid: 'oj7urici',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.5',
  //   zania_control_id: 'zania.CC3.1.5',
  // toe:'',
  //   control:
  //     'Complies With Applicable Accounting Standards-Financial reporting objectives are consistent with accounting principles suitable and available for that entity. The accounting principles selected are appropriate in the circumstances.',
  // },
  // {
  //   uid: 'yscaw44c',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.6',
  //   zania_control_id: 'zania.CC3.1.6',
  // toe:'',
  //   control:
  //     'Considers Materiality-Management considers materiality in financial statement presentation.',
  // },
  // {
  //   uid: '03l13n9i',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.7',
  //   zania_control_id: 'zania.CC3.1.7',
  // toe:'',
  //   control:
  //     'Reflects Entity Activities-External reporting reflects the underlying transactions and events to show qualitative characteristics and assertions.',
  // },
  // {
  //   uid: 'pionofq1',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.8',
  //   zania_control_id: 'zania.CC3.1.8',
  // toe:'',
  //   control:
  //     'Complies With Externally Established Frameworks-Management establishes objectives consistent with laws and regulations or standards and frameworks of recognized external organizations.',
  // },
  // {
  //   uid: '37r43ybt',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.9',
  //   zania_control_id: 'zania.CC3.1.9',
  // toe:'',
  //   control:
  //     'Considers the Required Level of Precision-Management reflects the required level of precision and accuracy suitable for user needs and based on criteria established by third parties in nonfinancial reporting.',
  // },
  // {
  //   uid: 'dr4x75fa',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.10',
  //   zania_control_id: 'zania.CC3.1.10',
  // toe:'',
  //   control:
  //     "Reflects Management's Choices-Internal reporting provides management with accurate and complete information regarding management's choices and information needed in managing the entity.",
  // },
  // {
  //   uid: 'j005ygud',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.11',
  //   zania_control_id: 'zania.CC3.1.11',
  // toe:'',
  //   control:
  //     'Considers Tolerances for Risk-Management considers the acceptable levels of variation relative to the achievement of operations objectives.',
  // },
  // {
  //   uid: 'fbjsok9y',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.12',
  //   zania_control_id: 'zania.CC3.1.12',
  // toe:'',
  //   control:
  //     'Reflects External Laws and Regulations-Laws and regulations establish minimum standards of conduct, which the entity integrates into compliance objectives.',
  // },
  // {
  //   uid: 'jlmda6l2',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.1',
  //   criteria:
  //     'COSO Principle 6: The entity specifies objectives with sufficient clarity to enable the identification and assessment of risks relating to objectives.',
  //   control_id: 'CC3.1.13',
  //   zania_control_id: 'zania.CC3.1.13',
  // toe:'',
  //   control:
  //     "Establishes Sub-objectives to Support Objectives-Management identifies sub-objectives related to security, availability, processing integrity, confidentiality, and privacy to support the achievement of the entity's objectives related to reporting, operations, and compliance.",
  // },
  // {
  //   uid: '1xllpgmu',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.1',
  //   zania_control_id: 'zania.CC3.2.1',
  // toe:'',
  //   control:
  //     'Includes Entity, Subsidiary, Division, Operating Unit, and Functional Levels-The entity identifies and assesses risk at the entity, subsidiary, division, operating unit, and functional levels relevant to the achievement of objectives.',
  // },
  // {
  //   uid: 'g9y3xwtx',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.2',
  //   zania_control_id: 'zania.CC3.2.2',
  // toe:'',
  //   control:
  //     'Analyzes Internal and External Factors-Risk identification considers both internal and external factors and their impact on the achievement of objectives.',
  // },
  // {
  //   uid: '8pv9xejd',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.3',
  //   zania_control_id: 'zania.CC3.2.3',
  // toe:'',
  //   control:
  //     'Involves Appropriate Levels of Management-The entity puts into place effective risk assessment mechanisms that involve appropriate levels of management.',
  // },
  // {
  //   uid: '9w712j38',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.4',
  //   zania_control_id: 'zania.CC3.2.4',
  // toe:'',
  //   control:
  //     'Estimates Significance of Risks Identified-Identified risks are analyzed through a process that includes estimating the potential significance of the risk.',
  // },
  // {
  //   uid: '6tq77fud',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.5',
  //   zania_control_id: 'zania.CC3.2.5',
  // toe:'',
  //   control:
  //     'Determines How to Respond to Risks-Risk assessment includes considering how the risk should be managed and whether to accept, avoid, reduce, or share the risk.',
  // },
  // {
  //   uid: 'qp9j7tzz',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.6',
  //   zania_control_id: 'zania.CC3.2.6',
  // toe:'',
  //   control:
  //     "Identifies and Assesses Criticality of Information Assets and Identifies Threats and Vulnerabilities-The entity's risk identification and assessment process includes (1) identifying information assets, including physical devices and systems, virtual devices, software, data and data flows, external information systems, and organizational roles; (2) assessing the criticality of those information assets; (3) identifying the threats to the assets from intentional (including malicious) and unintentional acts and environmental events; and (4) identifying the vulnerabilities of the identified assets.",
  // },
  // {
  //   uid: '4woci8ej',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.7',
  //   zania_control_id: 'zania.CC3.2.7',
  // toe:'',
  //   control:
  //     "Analyzes Threats and Vulnerabilities From Vendors, Business Partners, and Other Parties-The entity's risk assessment process includes the analysis of potential threats and vulnerabilities arising from vendors providing goods and services, as well as threats and vulnerabilities arising from business partners, customers, and others with access to the entity's information systems.",
  // },
  // {
  //   uid: 'h22tn2yv',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.2',
  //   criteria:
  //     'COSO Principle 7: The entity identifies risks to the achievement of its objectives across the entity and analyzes risks as a basis for determining how the risks should be managed.',
  //   control_id: 'CC3.2.8',
  //   zania_control_id: 'zania.CC3.2.8',
  // toe:'',
  //   control:
  //     "Considers the Significance of the Risk-The entity's consideration of the potential significance of the identified risks includes (1) determining the criticality of identified assets in meeting objectives; (2) assessing the impact of identified threats and vulnerabilities in meeting objectives; (3) assessing the likelihood of identified threats; and (4) determining the risk associated with assets based on asset criticality, threat impact, and likelihood.",
  // },
  // {
  //   uid: 'uht6x7ot',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.3',
  //   criteria:
  //     'COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.',
  //   control_id: 'CC3.3.1',
  //   zania_control_id: 'zania.CC3.3.1',
  // toe:'',
  //   control:
  //     'Considers Various Types of Fraud-The assessment of fraud considers fraudulent reporting, possible loss of assets, and corruption resulting from the various ways that fraud and misconduct can occur.',
  // },
  // {
  //   uid: 'gtz4ogmv',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.3',
  //   criteria:
  //     'COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.',
  //   control_id: 'CC3.3.2',
  //   zania_control_id: 'zania.CC3.3.2',
  // toe:'',
  //   control:
  //     'Assesses Incentives and Pressures-The assessment of fraud risks considers incentives and pressures.',
  // },
  // {
  //   uid: '9eym648e',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.3',
  //   criteria:
  //     'COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.',
  //   control_id: 'CC3.3.3',
  //   zania_control_id: 'zania.CC3.3.3',
  // toe:'',
  //   control:
  //     "Assesses Opportunities-The assessment of fraud risk considers opportunities for unauthorized acquisition, use, or disposal of assets, altering the entity's reporting records, or committing other inappropriate acts.",
  // },
  // {
  //   uid: '9zxgyon9',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.3',
  //   criteria:
  //     'COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.',
  //   control_id: 'CC3.3.4',
  //   zania_control_id: 'zania.CC3.3.4',
  // toe:'',
  //   control:
  //     'Assesses Attitudes and Rationalizations-The assessment of fraud risk considers how management and other personnel might engage in or justify inappropriate actions.',
  // },
  // {
  //   uid: '0t1pblbk',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.3',
  //   criteria:
  //     'COSO Principle 8: The entity considers the potential for fraud in assessing risks to the achievement of objectives.',
  //   control_id: 'CC3.3.5',
  //   zania_control_id: 'zania.CC3.3.5',
  // toe:'',
  //   control:
  //     'Considers the Risks Related to the Use of IT and Access to Information-The assessment of fraud risks includes consideration of threats and vulnerabilities that arise specifically from the use of IT and access to information.',
  // },
  // {
  //   uid: 'shdx4wpm',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.4',
  //   criteria:
  //     'COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.',
  //   control_id: 'CC3.4.1',
  //   zania_control_id: 'zania.CC3.4.1',
  // toe:'',
  //   control:
  //     'Assesses Changes in the External Environment-The risk identification process considers changes to the regulatory, economic, and physical environment in which the entity operates.',
  // },
  // {
  //   uid: 'kechfi3c',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.4',
  //   criteria:
  //     'COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.',
  //   control_id: 'CC3.4.2',
  //   zania_control_id: 'zania.CC3.4.2',
  // toe:'',
  //   control:
  //     'Assesses Changes in the Business Model-The entity considers the potential impacts of new business lines, dramatically altered compositions of existing business lines, acquired or divested business operations on the system of internal control, rapid growth, changing reliance on foreign geographies, and new technologies.',
  // },
  // {
  //   uid: 'yj3zudif',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.4',
  //   criteria:
  //     'COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.',
  //   control_id: 'CC3.4.3',
  //   zania_control_id: 'zania.CC3.4.3',
  // toe:'',
  //   control:
  //     'Assesses Changes in Leadership-The entity considers changes in management and respective attitudes and philosophies on the system of internal control.',
  // },
  // {
  //   uid: 'jav92tw6',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.4',
  //   criteria:
  //     'COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.',
  //   control_id: 'CC3.4.4',
  //   zania_control_id: 'zania.CC3.4.4',
  // toe:'',
  //   control:
  //     "Assess Changes in Systems and Technology-The risk identification process considers changes arising from changes in the entity's systems and changes in the technology environment.",
  // },
  // {
  //   uid: 'd251591k',
  //   category: 'Risk Assessment',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC3.4',
  //   criteria:
  //     'COSO Principle 9: The entity identifies and assesses changes that could significantly impact the system of internal control.',
  //   control_id: 'CC3.4.5',
  //   zania_control_id: 'zania.CC3.4.5',
  // toe:'',
  //   control:
  //     'Assess Changes in Vendor and Business Partner Relationships-The risk identification process considers changes in vendor and business partner relationships.',
  // },
  // {
  //   uid: 'f8uouev4',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.1',
  //   zania_control_id: 'zania.CC4.1.1',
  // toe:'',
  //   control:
  //     'Considers a Mix of Ongoing and Separate Evaluations-Management includes a balance of ongoing and separate evaluations.',
  // },
  // {
  //   uid: 'bkedb9kc',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.2',
  //   zania_control_id: 'zania.CC4.1.2',
  // toe:'',
  //   control:
  //     'Considers Rate of Change-Management considers the rate of change in business and business processes when selecting and developing ongoing and separate evaluations.',
  // },
  // {
  //   uid: 'vkd3f4zf',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.3',
  //   zania_control_id: 'zania.CC4.1.3',
  // toe:'',
  //   control:
  //     'Establishes Baseline Understanding-The design and current state of an internal control system are used to establish a baseline for ongoing and separate evaluations.',
  // },
  // {
  //   uid: '7g6rvcea',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.4',
  //   zania_control_id: 'zania.CC4.1.4',
  // toe:'',
  //   control:
  //     'Uses Knowledgeable Personnel-Evaluators performing ongoing and separate evaluations have sufficient knowledge to understand what is being evaluated.',
  // },
  // {
  //   uid: '8wccqexy',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.5',
  //   zania_control_id: 'zania.CC4.1.5',
  // toe:'',
  //   control:
  //     'Integrates With Business Processes-Ongoing evaluations are built into the business processes and adjust to changing conditions.',
  // },
  // {
  //   uid: '19u33a49',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.6',
  //   zania_control_id: 'zania.CC4.1.6',
  // toe:'',
  //   control:
  //     'Adjusts Scope and Frequency-Management varies the scope and frequency of separate evaluations depending on risk.',
  // },
  // {
  //   uid: 'sg944dy4',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.1',
  //   criteria:
  //     'COSO Principle 16: The entity selects, develops, and performs ongoing and/or separate evaluations to ascertain whether the components of internal control are present and functioning.',
  //   control_id: 'CC4.1.7',
  //   zania_control_id: 'zania.CC4.1.7',
  // toe:'',
  //   control:
  //     'Objectively Evaluates-Separate evaluations are performed periodically to provide objective feedback.',
  // },
  // {
  //   uid: 'kwr6spba',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.2',
  //   criteria:
  //     'COSO Principle 17: The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including  senior management and the board of directors, as  appropriate.',
  //   control_id: 'CC4.2.1',
  //   zania_control_id: 'zania.CC4.2.1',
  // toe:'',
  //   control:
  //     'Assesses Results - Management and the board of directors, as appropriate, assess results of ongoing and separate evaluations.',
  // },
  // {
  //   uid: '9sogkkic',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.2',
  //   criteria:
  //     'COSO Principle 17: The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including  senior management and the board of directors, as  appropriate.',
  //   control_id: 'CC4.2.2',
  //   zania_control_id: 'zania.CC4.2.2',
  // toe:'',
  //   control:
  //     'Communicates Deficiencies - Deficiencies are communicated to parties responsible for taking corrective action and to senior management and the board of directors, as appropriate.',
  // },
  // {
  //   uid: 'a2z97sc7',
  //   category: 'Monitoring Activities',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC4.2',
  //   criteria:
  //     'COSO Principle 17: The entity evaluates and communicates internal control deficiencies in a timely manner to those parties responsible for taking corrective action, including  senior management and the board of directors, as  appropriate.',
  //   control_id: 'CC4.2.3',
  //   zania_control_id: 'zania.CC4.2.3',
  // toe:'',
  //   control:
  //     'Monitors Corrective Actions - Management tracks whether deficiencies are remedied on a timely basis.',
  // },
  // {
  //   uid: '0u6qmnqa',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.1',
  //   zania_control_id: 'zania.CC5.1.1',
  // toe:'',
  //   control:
  //     'Integrates With Risk Assessment - Control activities help ensure that risk responses that address and mitigate risks are carried out.',
  // },
  // {
  //   uid: 'tiuwlns9',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.2',
  //   zania_control_id: 'zania.CC5.1.2',
  // toe:'',
  //   control:
  //     'Considers Entity-Specific Factors - Management considers how the environment, complexity, nature, and scope of its operations, as well as the specific characteristics of its organization, affect the selection and development of control activities.',
  // },
  // {
  //   uid: '0mm6nsf9',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.3',
  //   zania_control_id: 'zania.CC5.1.3',
  // toe:'',
  //   control:
  //     'Determines Relevant Business Processes - Management determines which relevant business processes require control activities.',
  // },
  // {
  //   uid: 'r50tk36l',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.4',
  //   zania_control_id: 'zania.CC5.1.4',
  // toe:'',
  //   control:
  //     'Evaluates a Mix of Control Activity Types - Control activities include a range and variety of controls and may include a balance of approaches to mitigate risks, considering both manual and automated controls, and preventive and detective controls.',
  // },
  // {
  //   uid: 'ccukgb9w',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.5',
  //   zania_control_id: 'zania.CC5.1.5',
  // toe:'',
  //   control:
  //     'Considers at What Level Activities Are Applied - Management considers control activities at various levels in the entity.',
  // },
  // {
  //   uid: 'u8j8gdzc',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.1',
  //   criteria:
  //     'COSO Principle 10: The entity selects and develops control activities that contribute to the mitigation of risks to the achievement of objectives to acceptable levels.',
  //   control_id: 'CC5.1.6',
  //   zania_control_id: 'zania.CC5.1.6',
  // toe:'',
  //   control:
  //     'Addresses Segregation of Duties - Management segregates incompatible duties, and where such segregation is not practical, management selects and develops alternative control activities.',
  // },
  // {
  //   uid: 'ak41jgdt',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.2',
  //   criteria:
  //     'COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.',
  //   control_id: 'CC5.2.1',
  //   zania_control_id: 'zania.CC5.2.1',
  // toe:'',
  //   control:
  //     'Determines Dependency Between the Use of Technology in Business Processes and Technology General Controls - Management understands and determines the dependency and linkage between business processes, automated control activities, and technology general controls.',
  // },
  // {
  //   uid: 'qnvdj17y',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.2',
  //   criteria:
  //     'COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.',
  //   control_id: 'CC5.2.2',
  //   zania_control_id: 'zania.CC5.2.2',
  // toe:'',
  //   control:
  //     'Establishes Relevant Technology Infrastructure Control Activities - Management selects and develops control activities over the technology infrastructure, which are designed and implemented to help ensure the completeness, accuracy, and availability of technology processing.',
  // },
  // {
  //   uid: 'f67ac1nd',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.2',
  //   criteria:
  //     'COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.',
  //   control_id: 'CC5.2.3',
  //   zania_control_id: 'zania.CC5.2.3',
  // toe:'',
  //   control:
  //     "Establishes Relevant Security Management Process Controls Activities - Management selects and develops control activities that are designed and implemented to restrict technology access rights to authorized users commensurate with their job responsibilities and to protect the entity's assets from external threats.",
  // },
  // {
  //   uid: 'fxv0cvvq',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.2',
  //   criteria:
  //     'COSO Principle 11: The entity also selects and develops general control activities over technology to support the achievement of objectives.',
  //   control_id: 'CC5.2.4',
  //   zania_control_id: 'zania.CC5.2.4',
  // toe:'',
  //   control:
  //     "Establishes Relevant Technology Acquisition, Development, and Maintenance Process Control Activities - Management selects and develops control activities over the acquisition, development, and maintenance of technology and its infrastructure to achieve management's objectives.",
  // },
  // {
  //   uid: 'cudqb6v9',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.1',
  //   zania_control_id: 'zania.CC5.3.1',
  // toe:'',
  //   control:
  //     "Establishes Policies and Procedures to Support Deployment of Management's Directives - Management establishes control activities that are built into business processes and employees' day-to-day activities through policies establishing what is expected and relevant procedures specifying actions.",
  // },
  // {
  //   uid: 'rtatok6x',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.2',
  //   zania_control_id: 'zania.CC5.3.2',
  // toe:'',
  //   control:
  //     'Establishes Responsibility and Accountability for Executing Policies and Procedures - Management establishes responsibility and accountability for control activities with management (or other designated personnel) of the business unit or function in which the relevant risks reside.',
  // },
  // {
  //   uid: '12i0up0v',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.3',
  //   zania_control_id: 'zania.CC5.3.3',
  // toe:'',
  //   control:
  //     'Performs in a Timely Manner - Responsible personnel perform control activities in a timely manner as defined by the policies and procedures.',
  // },
  // {
  //   uid: 'yerl8oha',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.4',
  //   zania_control_id: 'zania.CC5.3.4',
  // toe:'',
  //   control:
  //     'Takes Corrective Action - Responsible personnel investigate and act on matters identified as a result of executing control activities.',
  // },
  // {
  //   uid: '51wr72rc',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.5',
  //   zania_control_id: 'zania.CC5.3.5',
  // toe:'',
  //   control:
  //     'Performs Using Competent Personnel - Competent personnel with sufficient authority perform control activities with diligence and continuing focus.',
  // },
  // {
  //   uid: 't6rll5k5',
  //   category: 'Control Activities ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC5.3',
  //   criteria:
  //     'COSO Principle 12: The entity deploys control activities through policies that establish what is expected and in procedures that put policies into action.',
  //   control_id: 'CC5.3.6',
  //   zania_control_id: 'zania.CC5.3.6',
  // toe:'',
  //   control:
  //     'Reassesses Policies and Procedures - Management periodically reviews control activities to determine their continued relevance and refreshes them when necessary.',
  // },
  // {
  //   uid: 'pqkto7ch',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.1',
  //   zania_control_id: 'zania.CC6.1.1',
  // toe:'',
  //   control:
  //     'Identifies and Manages the Inventory of Information Assets - The entity identifies, inventories, classifies, and manages information assets.',
  // },
  // {
  //   uid: 'vvnk6s1p',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.2',
  //   zania_control_id: 'zania.CC6.1.2',
  // toe:'',
  //   control:
  //     'Restricts Logical Access - Logical access to information assets, including hardware, data (at-rest, during processing, or in transmission), software, administrative authorities, mobile devices, output, and offline system components is restricted through the use of access control software and rule sets.',
  // },
  // {
  //   uid: 'esd4f545',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.3',
  //   zania_control_id: 'zania.CC6.1.3',
  // toe:'',
  //   control:
  //     'Identifies and Authenticates Users - Persons, infrastructure, and software are identified and authenticated prior to accessing information assets, whether locally or remotely.',
  // },
  // {
  //   uid: 'hdo0h0id',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.4',
  //   zania_control_id: 'zania.CC6.1.4',
  // toe:'',
  //   control:
  //     "Considers Network Segmentation - Network segmentation permits unrelated portions of the entity's information system to be isolated from each other.",
  // },
  // {
  //   uid: 'vqkwmvf1',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.5',
  //   zania_control_id: 'zania.CC6.1.5',
  // toe:'',
  //   control:
  //     'Manages Points of Access - Points of access by outside entities and the types of data that flow through the points of access are identified, inventoried, and managed. The types of individuals and systems using each point of access are identified, documented, and managed.',
  // },
  // {
  //   uid: 'z1uzdfwh',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.6',
  //   zania_control_id: 'zania.CC6.1.6',
  // toe:'',
  //   control:
  //     'Restricts Access to Information Assets - Combinations of data classification, separate data structures, port restrictions, access protocol restrictions, user identification, and digital certificates are used to establish access control rules for information assets.',
  // },
  // {
  //   uid: 'y25isc9b',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.7',
  //   zania_control_id: 'zania.CC6.1.7',
  // toe:'',
  //   control:
  //     'Manages Identification and Authentication - Identification and authentication requirements are established, documented, and managed for individuals and systems accessing entity information, infrastructure, and software.',
  // },
  // {
  //   uid: '5sa7wmr4',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.8',
  //   zania_control_id: 'zania.CC6.1.8',
  // toe:'',
  //   control:
  //     'Manages Credentials for Infrastructure and Software - New internal and external infrastructure and software are registered, authorized, and documented prior to being granted access credentials and implemented on the network or access point. Credentials are removed and access is disabled when access is no longer required or the infrastructure and software are no longer in use.',
  // },
  // {
  //   uid: 'ntbn19iv',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.9',
  //   zania_control_id: 'zania.CC6.1.9',
  // toe:'',
  //   control:
  //     'Uses Encryption to Protect Data - The entity uses encryption to supplement other measures used to protect data-at-rest, when such protections are deemed appropriate based on assessed risk.',
  // },
  // {
  //   uid: 'cszgs5me',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.1',
  //   criteria:
  //     "The entity implements logical access security software, infrastructure, and architectures over protected information assets to protect them from security events to meet the entity's objectives.",
  //   control_id: 'CC6.1.10',
  //   zania_control_id: 'zania.CC6.1.10',
  // toe:'',
  //   control:
  //     'Protects Encryption Keys - Processes are in place to protect encryption keys during generation, storage, use, and destruction.',
  // },
  // {
  //   uid: 'bjcx830m',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.2',
  //   criteria:
  //     'Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.',
  //   control_id: 'CC6.2.1',
  //   zania_control_id: 'zania.CC6.2.1',
  // toe:'',
  //   control:
  //     "Controls Access Credentials to Protected Assets - Information asset access credentials are created based on an authorization from the system's asset owner or authorized custodian.",
  // },
  // {
  //   uid: 'thm25eq3',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.2',
  //   criteria:
  //     'Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.',
  //   control_id: 'CC6.2.2',
  //   zania_control_id: 'zania.CC6.2.2',
  // toe:'',
  //   control:
  //     'Removes Access to Protected Assets When Appropriate - Processes are in place to remove credential access when an individual no longer requires such access.',
  // },
  // {
  //   uid: 'nbvtxch6',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.2',
  //   criteria:
  //     'Prior to issuing system credentials and granting system access, the entity registers and authorizes new internal and external users whose access is administered by the entity. For those users whose access is administered by the entity, user system credentials are removed when user access is no longer authorized.',
  //   control_id: 'CC6.2.3',
  //   zania_control_id: 'zania.CC6.2.3',
  // toe:'',
  //   control:
  //     'Reviews Appropriateness of Access Credentials - The appropriateness of access credentials is reviewed on a periodic basis for unnecessary and inappropriate individuals with credentials.',
  // },
  // {
  //   uid: 'sqwvrqmk',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.3',
  //   criteria:
  //     "The entity authorizes, modifies, or removes access to data, software, functions, and other protected information assets based on roles, responsibilities, or the system design and changes, giving consideration to the concepts of least privilege and segregation of duties, to meet the entity's objectives.",
  //   control_id: 'CC6.3.1',
  //   zania_control_id: 'zania.CC6.3.1',
  // toe:'',
  //   control:
  //     "Creates or Modifies Access to Protected Information Assets - Processes are in place to create or modify access to protected information assets based on authorization from the asset's owner.",
  // },
  // {
  //   uid: 'jhzv9bk9',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.3',
  //   criteria:
  //     "The entity authorizes, modifies, or removes access to data, software, functions, and other protected information assets based on roles, responsibilities, or the system design and changes, giving consideration to the concepts of least privilege and segregation of duties, to meet the entity's objectives.",
  //   control_id: 'CC6.3.2',
  //   zania_control_id: 'zania.CC6.3.2',
  // toe:'',
  //   control:
  //     'Removes Access to Protected Information Assets - Processes are in place to remove access to protected information assets when an individual no longer requires access.',
  // },
  // {
  //   uid: 'ndtoh0mj',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.3',
  //   criteria:
  //     "The entity authorizes, modifies, or removes access to data, software, functions, and other protected information assets based on roles, responsibilities, or the system design and changes, giving consideration to the concepts of least privilege and segregation of duties, to meet the entity's objectives.",
  //   control_id: 'CC6.3.3',
  //   zania_control_id: 'zania.CC6.3.3',
  // toe:'',
  //   control:
  //     'Uses Role-Based Access Controls - Role-based access control is utilized to support segregation of incompatible functions.',
  // },
  // {
  //   uid: 'cvfu3uyr',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.4',
  //   criteria:
  //     "The entity restricts physical access to facilities and protected information assets (for example, data center facilities, back-up media storage, and other sensitive locations) to authorized personnel to meet the entity's objectives.",
  //   control_id: 'CC6.4.1',
  //   zania_control_id: 'zania.CC6.4.1',
  // toe:'',
  //   control:
  //     "Creates or Modifies Physical Access - Processes are in place to create or modify physical access to facilities such as data centers, office spaces, and work areas, based on authorization from the system's asset owner.",
  // },
  // {
  //   uid: 'yjf3283h',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.4',
  //   criteria:
  //     "The entity restricts physical access to facilities and protected information assets (for example, data center facilities, back-up media storage, and other sensitive locations) to authorized personnel to meet the entity's objectives.",
  //   control_id: 'CC6.4.2',
  //   zania_control_id: 'zania.CC6.4.2',
  // toe:'',
  //   control:
  //     'Removes Physical Access - Processes are in place to remove access to physical resources when an individual no longer requires access.',
  // },
  // {
  //   uid: 'qaska660',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.4',
  //   criteria:
  //     "The entity restricts physical access to facilities and protected information assets (for example, data center facilities, back-up media storage, and other sensitive locations) to authorized personnel to meet the entity's objectives.",
  //   control_id: 'CC6.4.3',
  //   zania_control_id: 'zania.CC6.4.3',
  // toe:'',
  //   control:
  //     'Reviews Physical Access - Processes are in place to periodically review physical access to ensure consistency with job responsibilities.',
  // },
  // {
  //   uid: 't5dp4k8m',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.5',
  //   criteria:
  //     "The entity discontinues logical and physical protections over physical assets only after the ability to read or recover data and software from those assets has been diminished and is no longer required to meet the entity's objectives.",
  //   control_id: 'CC6.5.1',
  //   zania_control_id: 'zania.CC6.5.1',
  // toe:'',
  //   control:
  //     'Identifies Data and Software for Disposal - Procedures are in place to identify data and software stored on equipment to be disposed and to render such data and software unreadable.',
  // },
  // {
  //   uid: 'x2mb1e1b',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.5',
  //   criteria:
  //     "The entity discontinues logical and physical protections over physical assets only after the ability to read or recover data and software from those assets has been diminished and is no longer required to meet the entity's objectives.",
  //   control_id: 'CC6.5.2',
  //   zania_control_id: 'zania.CC6.5.2',
  // toe:'',
  //   control:
  //     'Removes Data and Software From Entity Control - Procedures are in place to remove data and software stored on equipment to be removed from the physical control of the entity and to render such data and software unreadable.',
  // },
  // {
  //   uid: '2rnuupyu',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.6',
  //   criteria:
  //     'The entity implements logical access security measures to protect against threats from sources outside its system boundaries.',
  //   control_id: 'CC6.6.1',
  //   zania_control_id: 'zania.CC6.6.1',
  // toe:'',
  //   control:
  //     'Restricts Access - The types of activities that can occur through a communication channel (for example, FTP site, router port) are restricted.',
  // },
  // {
  //   uid: 'dszxd7mo',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.6',
  //   criteria:
  //     'The entity implements logical access security measures to protect against threats from sources outside its system boundaries.',
  //   control_id: 'CC6.6.2',
  //   zania_control_id: 'zania.CC6.6.2',
  // toe:'',
  //   control:
  //     'Protects Identification and Authentication Credentials - Identification and authentication credentials are protected during transmission outside its system boundaries.',
  // },
  // {
  //   uid: 'skqbrbdc',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.6',
  //   criteria:
  //     'The entity implements logical access security measures to protect against threats from sources outside its system boundaries.',
  //   control_id: 'CC6.6.3',
  //   zania_control_id: 'zania.CC6.6.3',
  // toe:'',
  //   control:
  //     'Requires Additional Authentication or Credentials - Additional authentication information or credentials are required when accessing the system from outside its boundaries.',
  // },
  // {
  //   uid: '1to55mhp',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.6',
  //   criteria:
  //     'The entity implements logical access security measures to protect against threats from sources outside its system boundaries.',
  //   control_id: 'CC6.6.4',
  //   zania_control_id: 'zania.CC6.6.4',
  // toe:'',
  //   control:
  //     'Implements Boundary Protection Systems - Boundary protection systems (for example, firewalls, demilitarized zones, and intrusion detection systems) are implemented to protect external access points from attempts and unauthorized access and are monitored to detect such attempts.',
  // },
  // {
  //   uid: 'j9lk3xir',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.7',
  //   criteria:
  //     "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity's objectives.",
  //   control_id: 'CC6.7.1',
  //   zania_control_id: 'zania.CC6.7.1',
  // toe:'',
  //   control:
  //     'Restricts the Ability to Perform Transmission - Data loss prevention processes and technologies are used to restrict the ability to authorize and execute the transmission, movement, and removal of information.',
  // },
  // {
  //   uid: 'obf51dtl',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.7',
  //   criteria:
  //     "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity's objectives.",
  //   control_id: 'CC6.7.2',
  //   zania_control_id: 'zania.CC6.7.2',
  // toe:'',
  //   control:
  //     'Uses Encryption Technologies or Secure Communication Channels to Protect Data - Encryption technologies or secured communication channels are used to protect transmission of data and other communications beyond connectivity access points.',
  // },
  // {
  //   uid: 'azhylpep',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.7',
  //   criteria:
  //     "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity's objectives.",
  //   control_id: 'CC6.7.3',
  //   zania_control_id: 'zania.CC6.7.3',
  // toe:'',
  //   control:
  //     'Protects Removal Media - Encryption technologies and physical asset protections are used for removable media (such as USB drives and back-up tapes), as appropriate.',
  // },
  // {
  //   uid: 'kkcxb459',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.7',
  //   criteria:
  //     "The entity restricts the transmission, movement, and removal of information to authorized internal and external users and processes, and protects it during transmission, movement, or removal to meet the entity's objectives.",
  //   control_id: 'CC6.7.4',
  //   zania_control_id: 'zania.CC6.7.4',
  // toe:'',
  //   control:
  //     'Protects Mobile Devices - Processes are in place to protect mobile devices (such as laptops, smartphones, and tablets) that serve as information assets.',
  // },
  // {
  //   uid: 'red71crc',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.8',
  //   criteria:
  //     "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity's objectives.",
  //   control_id: 'CC6.8.1',
  //   zania_control_id: 'zania.CC6.8.1',
  // toe:'',
  //   control:
  //     'Restricts Application and Software Installation - The ability to install applications and software is restricted to authorized individuals.',
  // },
  // {
  //   uid: 'bwgw4h1z',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.8',
  //   criteria:
  //     "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity's objectives.",
  //   control_id: 'CC6.8.2',
  //   zania_control_id: 'zania.CC6.8.2',
  // toe:'',
  //   control:
  //     'Detects Unauthorized Changes to Software and Configuration Parameters - Processes are in place to detect changes to software and configuration parameters that may be indicative of unauthorized or malicious software.',
  // },
  // {
  //   uid: 'x6nqv3nx',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.8',
  //   criteria:
  //     "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity's objectives.",
  //   control_id: 'CC6.8.3',
  //   zania_control_id: 'zania.CC6.8.3',
  // toe:'',
  //   control:
  //     'Uses a Defined Change Control Process - A management-defined change control process is used for the implementation of software.',
  // },
  // {
  //   uid: 'sh2drxj5',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.8',
  //   criteria:
  //     "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity's objectives.",
  //   control_id: 'CC6.8.4',
  //   zania_control_id: 'zania.CC6.8.4',
  // toe:'',
  //   control:
  //     'Uses Antivirus and Anti-Malware Software - Antivirus and anti-malware software is implemented and maintained to provide for the interception or detection and remediation of malware.',
  // },
  // {
  //   uid: 'u6wc217b',
  //   category: 'Logical and Physical Access Controls',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC6.8',
  //   criteria:
  //     "The entity implements controls to prevent or detect and act upon the introduction of unauthorized or malicious software to meet the entity's objectives.",
  //   control_id: 'CC6.8.5',
  //   zania_control_id: 'zania.CC6.8.5',
  // toe:'',
  //   control:
  //     "Scans Information Assets from Outside the Entity for Malware and Other Unauthorized Software - Procedures are in place to scan information assets that have been transferred or returned to the entity's custody for malware and other unauthorized software and to remove any items detected prior to its implementation on the network.",
  // },
  // {
  //   uid: 'df0qjpue',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.1',
  //   criteria:
  //     'To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.',
  //   control_id: 'CC7.1.1',
  //   zania_control_id: 'zania.CC7.1.1',
  // toe:'',
  //   control:
  //     'Uses Defined Configuration Standards - Management has defined configuration standards.',
  // },
  // {
  //   uid: 'nx2irayd',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.1',
  //   criteria:
  //     'To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.',
  //   control_id: 'CC7.1.2',
  //   zania_control_id: 'zania.CC7.1.2',
  // toe:'',
  //   control:
  //     "Monitors Infrastructure and Software - The entity monitors infrastructure and software for noncompliance with the standards, which could threaten the achievement of the entity's objectives.",
  // },
  // {
  //   uid: '3o3xunpo',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.1',
  //   criteria:
  //     'To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.',
  //   control_id: 'CC7.1.3',
  //   zania_control_id: 'zania.CC7.1.3',
  // toe:'',
  //   control:
  //     'Implements Change-Detection Mechanisms - The IT system includes a change-detection mechanism (for example, file integrity monitoring tools) to alert personnel to unauthorized modifications of critical system files, configuration files, or content files.',
  // },
  // {
  //   uid: 'pz0pzgdr',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.1',
  //   criteria:
  //     'To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.',
  //   control_id: 'CC7.1.4',
  //   zania_control_id: 'zania.CC7.1.4',
  // toe:'',
  //   control:
  //     'Detects Unknown or Unauthorized Components - Procedures are in place to detect the introduction of unknown or unauthorized components.',
  // },
  // {
  //   uid: 'k4rgmhjr',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.1',
  //   criteria:
  //     'To meet its objectives, the entity uses detection and monitoring procedures to identify (1) changes to configurations that result in the introduction of new vulnerabilities, and (2) susceptibilities to newly discovered vulnerabilities.',
  //   control_id: 'CC7.1.5',
  //   zania_control_id: 'zania.CC7.1.5',
  // toe:'',
  //   control:
  //     'Conducts Vulnerability Scans - The entity conducts vulnerability scans designed to identify potential vulnerabilities or misconfigurations on a periodic basis and after any significant change in the environment and takes action to remediate identified deficiencies on a timely basis.',
  // },
  // {
  //   uid: 'naw9bd68',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.2',
  //   criteria:
  //     "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
  //   control_id: 'CC7.2.1',
  //   zania_control_id: 'zania.CC7.2.1',
  // toe:'',
  //   control:
  //     'Implements Detection Policies, Procedures, and Tools - Detection policies and procedures are defined and implemented, and detection tools are implemented on infrastructure and software to identify anomalies in the operation or unusual activity on systems. Procedures may include (1) a defined governance process for security event detection and management that includes provision of resources; (2) use of intelligence sources to identify newly discovered threats and vulnerabilities; and (3) logging of unusual system activities.',
  // },
  // {
  //   uid: 'kp3du6so',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.2',
  //   criteria:
  //     "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
  //   control_id: 'CC7.2.2',
  //   zania_control_id: 'zania.CC7.2.2',
  // toe:'',
  //   control:
  //     'Designs Detection Measures - Detection measures are designed to identify anomalies that could result from actual or attempted (1) compromise of physical barriers; (2) unauthorized actions of authorized personnel; (3) use of compromised identification and authentication credentials; (4) unauthorized access from outside the system boundaries; (5) compromise of authorized external parties; and (6) implementation or connection of unauthorized hardware and software.',
  // },
  // {
  //   uid: 'z16nid20',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.2',
  //   criteria:
  //     "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
  //   control_id: 'CC7.2.3',
  //   zania_control_id: 'zania.CC7.2.3',
  // toe:'',
  //   control:
  //     'Implements Filters to Analyze Anomalies - Management has implemented procedures to filter, summarize, and analyze anomalies to identify security events.',
  // },
  // {
  //   uid: 't6deyhrm',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.2',
  //   criteria:
  //     "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
  //   control_id: 'CC7.2.4',
  //   zania_control_id: 'zania.CC7.2.4',
  // toe:'',
  //   control:
  //     'Responds to Security Incidents - Procedures are in place for responding to security incidents and evaluating the effectiveness of those policies and procedures on a periodic basis.',
  // },
  // {
  //   uid: 'hoqqm8bn',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.2',
  //   criteria:
  //     "The entity monitors system components and the operation of those components for anomalies that are indicative of malicious acts, natural disasters, and errors affecting the entity's ability to meet its objectives; anomalies are analyzed to determine whether they represent security events.",
  //   control_id: 'CC7.2.5',
  //   zania_control_id: 'zania.CC7.2.5',
  // toe:'',
  //   control:
  //     'Communicates and Reviews Detected Security Events - Detected security events are communicated to and reviewed by the individuals responsible for the management of the security program and actions are taken, if necessary.',
  // },
  // {
  //   uid: '12c803ig',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.3',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.3.1',
  //   zania_control_id: 'zania.CC7.3.1',
  // toe:'',
  //   control:
  //     'Develops and Implements Procedures to Analyze Security Incidents - Procedures are in place to analyze security incidents and determine system impact.',
  // },
  // {
  //   uid: '94luna12',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.1',
  //   zania_control_id: 'zania.CC7.4.1',
  // toe:'',
  //   control:
  //     'Assigns Roles and Responsibilities - Roles and responsibilities for the design, implementation, maintenance, and execution of the incident response program are assigned, including the use of external resources when necessary.',
  // },
  // {
  //   uid: 't0s54so0',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.2',
  //   zania_control_id: 'zania.CC7.4.2',
  // toe:'',
  //   control:
  //     'Contains Security Incidents - Procedures are in place to contain security incidents that actively threaten entity objectives.',
  // },
  // {
  //   uid: 'alzmxqjg',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.3',
  //   zania_control_id: 'zania.CC7.4.3',
  // toe:'',
  //   control:
  //     'Mitigates Ongoing Security Incidents - Procedures are in place to mitigate the effects of ongoing security incidents.',
  // },
  // {
  //   uid: '26slupxe',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.4',
  //   zania_control_id: 'zania.CC7.4.4',
  // toe:'',
  //   control:
  //     'Ends Threats Posed by Security Incidents - Procedures are in place to end the threats posed by security incidents through closure of the vulnerability, removal of unauthorized access, and other remediation actions.',
  // },
  // {
  //   uid: 'y5ssq0np',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.5',
  //   zania_control_id: 'zania.CC7.4.5',
  // toe:'',
  //   control:
  //     'Restores Operations - Procedures are in place to restore data and business operations to an interim state that permits the achievement of entity objectives.',
  // },
  // {
  //   uid: 'rhx3co98',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.6',
  //   zania_control_id: 'zania.CC7.4.6',
  // toe:'',
  //   control:
  //     "Develops and Implements Communication Protocols for Security Incidents - Protocols for communicating security incidents and actions taken to affected parties are developed and implemented to meet the entity's objectives.",
  // },
  // {
  //   uid: '6o87jdpl',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.7',
  //   zania_control_id: 'zania.CC7.4.7',
  // toe:'',
  //   control:
  //     'Obtains Understanding of Nature of Incident and Determines Containment Strategy - An understanding of the nature (for example, the method by which the incident occurred and the affected system resources) and severity of the security incident is obtained to determine the appropriate containment strategy, including (1) a determination of the appropriate response time frame, and (2) the determination and execution of the containment approach.',
  // },
  // {
  //   uid: 'v8rhkxz3',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.8',
  //   zania_control_id: 'zania.CC7.4.8',
  // toe:'',
  //   control:
  //     'Remediates Identified Vulnerabilities - Identified vulnerabilities are remediated through the development and execution of remediation activities.',
  // },
  // {
  //   uid: 'rnwu6yq9',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.9',
  //   zania_control_id: 'zania.CC7.4.9',
  // toe:'',
  //   control:
  //     'Communicates Remediation Activities - Remediation activities are documented and communicated in accordance with the incident response program.',
  // },
  // {
  //   uid: 'i5sa40t6',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.10',
  //   zania_control_id: 'zania.CC7.4.10',
  // toe:'',
  //   control:
  //     'Evaluates the Effectiveness of Incident Response - The design of incident response activities is evaluated for effectiveness on a periodic basis.',
  // },
  // {
  //   uid: '73zitqwg',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.4',
  //   criteria:
  //     'The entity evaluates security events to determine whether they could or have resulted in a failure of the entity to meet its objectives (security incidents) and, if so, takes actions to prevent or address such failures.',
  //   control_id: 'CC7.4.11',
  //   zania_control_id: 'zania.CC7.4.11',
  // toe:'',
  //   control:
  //     'Periodically Evaluates Incidents - Periodically, management reviews incidents related to security, availability, processing integrity, confidentiality, and privacy and identifies the need for system changes based on incident patterns and root causes.',
  // },
  // {
  //   uid: '5hbeqmpe',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.1',
  //   zania_control_id: 'zania.CC7.5.1',
  // toe:'',
  //   control:
  //     'Restores the Affected Environment - The activities restore the affected environment to functional operation by rebuilding systems, updating software, installing patches, and changing configurations, as needed.',
  // },
  // {
  //   uid: 'ty6x0aar',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.2',
  //   zania_control_id: 'zania.CC7.5.2',
  // toe:'',
  //   control:
  //     'Communicates Information About the Event - Communications about the nature of the incident, recovery actions taken, and activities required for the prevention of future security events are made to management and others as appropriate (internal and external).',
  // },
  // {
  //   uid: 'taojukya',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.3',
  //   zania_control_id: 'zania.CC7.5.3',
  // toe:'',
  //   control:
  //     'Determines Root Cause of the Event - The root cause of the event is determined.',
  // },
  // {
  //   uid: 'n3r3qrm8',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.4',
  //   zania_control_id: 'zania.CC7.5.4',
  // toe:'',
  //   control:
  //     'Implements Changes to Prevent and Detect Recurrences - Additional architecture or changes to preventive and detective controls, or both, are implemented to prevent and detect recurrences on a timely basis.',
  // },
  // {
  //   uid: 'ox77fpid',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.5',
  //   zania_control_id: 'zania.CC7.5.5',
  // toe:'',
  //   control:
  //     'Improves Response and Recovery Procedures - Lessons learned are analyzed, and the incident response plan and recovery procedures are improved.',
  // },
  // {
  //   uid: 'ykwz8ifp',
  //   category: 'System Operations',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC7.5',
  //   criteria:
  //     'The entity identifies, develops, and implements activities to recover from identified security incidents.',
  //   control_id: 'CC7.5.6',
  //   zania_control_id: 'zania.CC7.5.6',
  // toe:'',
  //   control:
  //     'Implements Incident Recovery Plan Testing - Incident recovery plan testing is performed on a periodic basis. The testing includes (1) development of testing scenarios based on threat likelihood and magnitude; (2) consideration of relevant system components from across the entity that can impair availability; (3) scenarios that consider the potential for the lack of availability of key personnel; and (4) revision of continuity plans and systems based on test results.',
  // },
  // {
  //   uid: 'idmdij9g',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.1',
  //   zania_control_id: 'zania.CC8.1.1',
  // toe:'',
  //   control:
  //     'A process for managing system changes throughout the lifecycle of the system and its components (infrastructure, data, software, and procedures) is used to support system availability and processing integrity.',
  // },
  // {
  //   uid: '5cq0rf4l',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.2',
  //   zania_control_id: 'zania.CC8.1.2',
  // toe:'',
  //   control:
  //     'Authorizes Changes - A process is in place to authorize system changes prior to development.',
  // },
  // {
  //   uid: 'b7nh0b91',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.3',
  //   zania_control_id: 'zania.CC8.1.3',
  // toe:'',
  //   control:
  //     'Designs and Develops Changes - A process is in place to design and develop system changes.',
  // },
  // {
  //   uid: 'v1wb7hdw',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.4',
  //   zania_control_id: 'zania.CC8.1.4',
  // toe:'',
  //   control:
  //     'Documents Changes - A process is in place to document system changes to support ongoing maintenance of the system and to support system users in performing their responsibilities.',
  // },
  // {
  //   uid: 'm0oln6kq',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.5',
  //   zania_control_id: 'zania.CC8.1.5',
  // toe:'',
  //   control:
  //     'Tracks System Changes - A process is in place to track system changes prior to implementation.',
  // },
  // {
  //   uid: 'h9suokud',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.6',
  //   zania_control_id: 'zania.CC8.1.6',
  // toe:'',
  //   control:
  //     'Configures Software - A process is in place to select and implement the configuration parameters used to control the functionality of software.',
  // },
  // {
  //   uid: 'qruciemr',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.7',
  //   zania_control_id: 'zania.CC8.1.7',
  // toe:'',
  //   control:
  //     'Tests System Changes - A process is in place to test system changes prior to implementation.',
  // },
  // {
  //   uid: 'eswdat2i',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.8',
  //   zania_control_id: 'zania.CC8.1.8',
  // toe:'',
  //   control:
  //     'Approves System Changes - A process is in place to approve system changes prior to implementation.',
  // },
  // {
  //   uid: 'gxr13sub',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.9',
  //   zania_control_id: 'zania.CC8.1.9',
  // toe:'',
  //   control:
  //     'Deploys System Changes - A process is in place to implement system changes.',
  // },
  // {
  //   uid: 'tbt8tsb1',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.10',
  //   zania_control_id: 'zania.CC8.1.10',
  // toe:'',
  //   control:
  //     'Identifies and Evaluates System Changes - Objectives affected by system changes are identified, and the ability of the modified system to meet the objectives is evaluated throughout the system development life cycle.',
  // },
  // {
  //   uid: 'aortxob8',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.11',
  //   zania_control_id: 'zania.CC8.1.11',
  // toe:'',
  //   control:
  //     'Identifies Changes in Infrastructure, Data, Software, and Procedures Required to Remediate Incidents - Changes in infrastructure, data, software, and procedures required to remediate incidents to continue to meet objectives are identified, and the change process is initiated upon identification.',
  // },
  // {
  //   uid: '6od1gcbg',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.12',
  //   zania_control_id: 'zania.CC8.1.12',
  // toe:'',
  //   control:
  //     'Creates Baseline Configuration of IT Technology - A baseline configuration of IT and control systems is created and maintained.',
  // },
  // {
  //   uid: '1gglq6sg',
  //   category: 'Change Management ',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC8.1',
  //   criteria:
  //     'The entity authorizes, designs, develops or acquires, configures, documents, tests, approves, and implements changes to infrastructure, data, software, and procedures to meet its objectives.',
  //   control_id: 'CC8.1.13',
  //   zania_control_id: 'zania.CC8.1.13',
  // toe:'',
  //   control:
  //     'Provides for Changes Necessary in Emergency Situations - A process is in place for authorizing, designing, testing, approving, and implementing changes necessary in emergency situations (that is, changes that need to be implemented in an urgent timeframe).',
  // },
  // {
  //   uid: 'xpkn252c',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.1',
  //   criteria:
  //     'The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.',
  //   control_id: 'CC9.1.1',
  //   zania_control_id: 'zania.CC9.1.1',
  // toe:'',
  //   control:
  //     "Considers Mitigation of Risks of Business Disruption - Risk mitigation activities include the development of planned policies, procedures, communications, and alternative processing solutions to respond to, mitigate, and recover from security events that disrupt business operations. Those policies and procedures include monitoring processes and information and communications to meet the entity's objectives during response, mitigation, and recovery efforts.",
  // },
  // {
  //   uid: 'ruvstgna',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.1',
  //   criteria:
  //     'The entity identifies, selects, and develops risk mitigation activities for risks arising from potential business disruptions.',
  //   control_id: 'CC9.1.2',
  //   zania_control_id: 'zania.CC9.1.2',
  // toe:'',
  //   control:
  //     'Considers the Use of Insurance to Mitigate Financial Impact Risks - The risk management activities consider the use of insurance to offset the financial impact of loss events that would otherwise impair the ability of the entity to meet its objectives.',
  // },
  // {
  //   uid: '5rg8pp0s',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.1',
  //   zania_control_id: 'zania.CC9.2.1',
  // toe:'',
  //   control:
  //     'Establishes Requirements for Vendor and Business Partner Engagements - The entity establishes specific requirements for a vendor and business partner engagement that includes (1) scope of services and product specifications, (2) roles and responsibilities, (3) compliance requirements, and (4) service levels.',
  // },
  // {
  //   uid: 'zp3akfm3',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.2',
  //   zania_control_id: 'zania.CC9.2.2',
  // toe:'',
  //   control:
  //     "Assesses Vendor and Business Partner Risks - The entity assesses, on a periodic basis, the risks that vendors and business partners (and those entities' vendors and business partners) represent to the achievement of the entity's objectives.",
  // },
  // {
  //   uid: 'fgnph936',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.3',
  //   zania_control_id: 'zania.CC9.2.3',
  // toe:'',
  //   control:
  //     'Assigns Responsibility and Accountability for Managing Vendors and Business Partners - The entity assigns responsibility and accountability for the management of risks associated with vendors and business partners.',
  // },
  // {
  //   uid: 'smmxm2rb',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.4',
  //   zania_control_id: 'zania.CC9.2.4',
  // toe:'',
  //   control:
  //     'Establishes Communication Protocols for Vendors and Business Partners - The entity establishes communication and resolution protocols for service or product issues related to vendors and business partners.',
  // },
  // {
  //   uid: 'wf2sxz1e',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.5',
  //   zania_control_id: 'zania.CC9.2.5',
  // toe:'',
  //   control:
  //     'Establishes Exception Handling Procedures From Vendors and Business Partners - The entity establishes exception handling procedures for service or product issues related to vendors and business partners.',
  // },
  // {
  //   uid: 'ypv2ekqr',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.6',
  //   zania_control_id: 'zania.CC9.2.6',
  // toe:'',
  //   control:
  //     'Assesses Vendor and Business Partner Performance - The entity periodically assesses the performance of vendors and business partners.',
  // },
  // {
  //   uid: '1khsnl8u',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.7',
  //   zania_control_id: 'zania.CC9.2.7',
  // toe:'',
  //   control:
  //     'Implements Procedures for Addressing Issues Identified During Vendor and Business Partner Assessments - The entity implements procedures for addressing issues identified with vendor and business partner relationships.',
  // },
  // {
  //   uid: 'xaskk0h2',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.8',
  //   zania_control_id: 'zania.CC9.2.8',
  // toe:'',
  //   control:
  //     'Implements Procedures for Terminating Vendor and Business Partner Relationships - The entity implements procedures for terminating vendor and business partner relationships.',
  // },
  // {
  //   uid: 'dgigorgi',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.9',
  //   zania_control_id: 'zania.CC9.2.9',
  // toe:'',
  //   control:
  //     "Obtains Confidentiality Commitments from Vendors and Business Partners - The entity obtains confidentiality commitments that are consistent with the entity's confidentiality commitments and requirements from vendors and business partners who have access to confidential information.",
  // },
  // {
  //   uid: 'hmffvdjh',
  //   category: 'Risk Mitigation',
  //   tsc: TSCENUM.SECURITY,
  //   tsc_id: 'CC9.2',
  //   criteria:
  //     'The entity assesses and manages risks associated with vendors and business partners.',
  //   control_id: 'CC9.2.10',
  //   zania_control_id: 'zania.CC9.2.10',
  // toe:'',
  //   control:
  //     "Assesses Compliance With Confidentiality Commitments of Vendors and Business Partners - On a periodic and as-needed basis, the entity assesses compliance by vendors and business partners with the entity's confidentiality commitments and requirements.",
  // },
];
const initialState: Omit<CustomizeControlsStore, "actions" | "sectionWiseDataState"> = {
  accordianOpenState: {
    availability: true,
    confidentiality: true,
    "processing integrity": true,
    privacy: true,
    security: true,
  },
  dataState: [],
  selectedControls: new Set<string>(),
};
export const transformListOfControlToSectionWiseControls = (
  dataState: Control[],
  accordianOpenState: {
    [key in TSCENUM]: boolean;
  },
) => {
  const sectionData = dataState.reduce(
    (acc, next) => {
      if (next.tsc in acc) {
        acc[next.tsc].push(next);
      } else {
        acc[next.tsc] = [next];
      }
      return acc;
    },
    {
      availability: [],
      confidentiality: [],
      "processing integrity": [],
      privacy: [],
      security: [],
    } as { [key in TSCENUM]: Control[] },
  );

  const output = [] as Control[];

  for (const key of Object.keys(sectionData)) {
    output.push({
      category: "",
      toe: "",
      tod: "",
      control: "",
      control_id: "",
      criteria: "",
      tsc: key as TSCENUM,
      tsc_id: key,
      uid: uuid(),
      zania_control_id: "",
      edited_state: "ACCORDIAN",
      selected: false,
    });
    if (accordianOpenState[key as TSCENUM]) {
      output.push(...sectionData[key as TSCENUM]);
      output.push({
        category: "",
        toe: "",
        tod: "",
        control: "",
        control_id: "",
        criteria: "",
        tsc: key as TSCENUM,
        tsc_id: "",
        uid: uuid(),
        zania_control_id: "",
        edited_state: "NEW",
        selected: false,
      });
    }
  }

  return output;
};
export const useCustomizeControlsStore = create<CustomizeControlsStore>()(
  mutative((set, get) => ({
    ...initialState,
    actions: {
      setAccordianOpenState: (key: TSCENUM, newValue: boolean) => {
        set({
          accordianOpenState: {
            ...get().accordianOpenState,
            [key]: newValue,
          },
        });
      },
      setDataState: (newValue) => {
        set({
          dataState: typeof newValue === "function" ? newValue(get().dataState) : newValue,
        });
      },
      setSelectedControls: (newValue) => {
        const newSelectedControls = typeof newValue === "function" ? newValue(get().selectedControls) : newValue;

        set({
          selectedControls: newSelectedControls,
          dataState: get().dataState.map((d) => ({
            ...d,
            selected: newSelectedControls.has(d.uid),
          })),
        });
      },
      reset: () => {
        set({
          ...initialState,
        });
      },
    },
  })),
);

export const useGetAccordionState = () => useCustomizeControlsStore().accordianOpenState;

export const getCustomizeControlsActions = () => useCustomizeControlsStore.getState().actions;

export const getCustomizeControls = () => useCustomizeControlsStore.getState().dataState;

// export const useGetAccordionStateByTSC = (tsc: TSCENUM | undefined) =>
//   tsc === undefined
//     ? false
//     : useCustomizeControlsStore().accordianOpenState[tsc];

export const isControlValid = (control: Control): boolean => {
  if (control.tsc_id.trim().length === 0 && control.control_id.trim().length === 0) {
    return false;
  }
  if (control.tsc === TSCENUM.SECURITY && (control.category?.trim().length ?? 0) === 0) {
    return false;
  }
  if (control.control.trim().length === 0) {
    return false;
  }
  if (control.toe.trim().length === 0 || control.tod.trim().length === 0) {
    return false;
  }

  return true;
};
